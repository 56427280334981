@keyframes cosmic-pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.02);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

@keyframes aurora-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a {
  color: #00ffff;
  text-decoration: underline;
}

body {
  background-color: #120b29; /* Deeper space purple */
  background-image: radial-gradient(
      circle at 30% 30%,
      rgba(255, 0, 255, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 70% 70%,
      rgba(0, 255, 255, 0.15) 0%,
      transparent 50%
    ),
    radial-gradient(
      circle at 50% 50%,
      rgba(255, 215, 0, 0.1) 0%,
      transparent 60%
    );
  color: #fff;
  font-family: 'Courier New', monospace;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/*
.App {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100vh;
  position: relative;
}
*/

/* Sidebar styling */
.sidebar-content {
  background: linear-gradient(
    145deg,
    rgba(18, 11, 41, 0.8) 0%,
    rgba(71, 11, 75, 0.8) 50%,
    rgba(18, 11, 41, 0.8) 100%
  );
  backdrop-filter: blur(10px);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(255, 0, 255, 0.2);
  animation: aurora-flow 15s ease infinite;
  background-size: 200% 200%;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
  border: 3px solid #ff00ff;
  transition: all 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.05);
}

.profile-title {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  margin: 1rem 0;
  text-shadow: 0 0 10px rgba(138, 43, 226, 0.8);
}

/* Navigation styling */
button {
  background: rgba(255, 0, 255, 0.1);
  border: 2px solid rgba(0, 255, 255, 0.3);
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: linear-gradient(
    45deg,
    rgba(255, 0, 255, 0.2),
    rgba(0, 255, 255, 0.2)
  );
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transition: all 0.6s ease;
}

button:hover::before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

button:hover {
  border-color: #00ffff;
  box-shadow:
    0 0 15px rgba(0, 255, 255, 0.5),
    0 0 30px rgba(255, 0, 255, 0.3);
  transform: translateY(-2px) scale(1.05);
}

button.active {
  background: linear-gradient(
    45deg,
    rgba(255, 0, 255, 0.8),
    rgba(0, 255, 255, 0.8)
  );
  border-color: transparent;
  box-shadow:
    0 0 20px rgba(255, 0, 255, 0.5),
    0 0 40px rgba(0, 255, 255, 0.3);
}

.main-content::before {
  background: radial-gradient(
      circle at 30% 30%,
      rgba(255, 0, 255, 0.1) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 70% 70%,
      rgba(0, 255, 255, 0.1) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 50% 50%,
      rgba(255, 215, 0, 0.05) 0%,
      transparent 70%
    );
  pointer-events: none;
}

/* Section styling */
section {
  background: rgba(18, 11, 41, 0.7);
  border: 1px solid rgba(0, 255, 255, 0.2);
  border-radius: 8px;
  padding: 2rem;
  margin: 1rem 0;
  backdrop-filter: blur(10px);
  box-shadow:
    0 0 20px rgba(255, 0, 255, 0.1),
    0 0 40px rgba(0, 255, 255, 0.1);
}

/* Social icons styling */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: auto;
}

.social-icons a {
  color: #00ffff;
  font-size: 24px;
  transition: all 0.3s ease;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

.social-icons a:hover {
  color: #ff00ff;
  text-shadow:
    0 0 10px rgba(255, 0, 255, 0.8),
    0 0 20px rgba(255, 0, 255, 0.4);
  transform: scale(1.2) rotate(15deg);
}

/* Animated gradient text */
h1,
h2 {
  background: linear-gradient(
    90deg,
    #ff00ff,
    /* Magenta */ #00ffff,
    /* Cyan */ #ffff00,
    /* Yellow */ #ff00ff /* Back to magenta */
  );
  background-size: 300% auto;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: aurora-flow 3s linear infinite;
}
